<template>
  <div
    class="clinician-overlay"
    :class="{'no-header':hideCloseButton && !title && !showArrowBack}"
  >
    <transition name="slide-fade">
      <div
        ref="overlayContent"
        class="overlay-content-wrapper"
        :class="{'overlay-content-wrapper-right': position === 'right'}"
        :style="getStyle()"
      >
        <div
          class="overlay-header"
          :class="{'with-title': title, 'with-arrow': showArrowBack}"
        >
          <slot name="header">
            <i
              v-if="showArrowBack"
              class="fal fa-long-arrow-left fa-2x"
              @click="$emit('goBack')"
            />
            <span
              v-if="title"
              class="title"
              v-html="title"
            />
            <i
              v-if="!hideCloseButton && !exitText"
              class="fal fa-times"
              @click="beforeClose"
            />
            <span
              v-if="!hideCloseButton && exitText"
              class="exit-text"
              @click="beforeClose"
            >{{ exitText }}</span>
          </slot>
        </div>
        <div
          id="clinician-overlay-content"
          class="overlay-content"
        >
          <slot
            v-if="!isLoading"
            name="content"
          />
          <LoadingSpinner
            v-else
            class="text-center"
          />
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import LoadingSpinner from '@/components/common/LoadingSpinner'
export default {
  name: 'Overlay',
  components: { LoadingSpinner },
  props: {
    /* eslint-disable */
    title: {},
    showArrowBack: {},
    width: {},
    promptBeforeClose: {},
    hideCloseButton: {},
    isLoading: {
      type: Boolean,
      default: false
    },
    position: {
      default: 'left'
    },
    exitText: {
      default: ''
    }
    /* eslint-enable */
  },
  computed: {
    ...mapGetters({
      formDirty: 'getActiveFormDirty'
    })
  },
  beforeDestroy () {
    document.body.classList.remove('overlay-opened')
    document.body.classList.remove(this.position)
  },
  created () {
    document.body.classList.add('overlay-opened')
    document.body.classList.add(this.position)
  },
  methods: {
    beforeClose () {
      if (typeof this.promptBeforeClose === 'function' && this.formDirty) {
        this.promptBeforeClose().then(() => {
          this.close()
        })
      } else {
        this.close()
      }
    },
    close () {
      this.$refs.overlayContent.classList.add('out')
      setTimeout(() => {
        this.$emit('close')
      }, 200)
    },
    getStyle () {
      const styles = {}
      if (this.width) {
        styles.width = this.width + 'px'
      }

      return styles
    }
  }
}
</script>
