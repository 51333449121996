<template>
  <Overlay
    class="clinician-edit-measure-responses"
    :exit-text="$t('exit')"
    :prompt-before-close="promptBeforeClose"
    :title="overlayTitle"
    @close="close"
  >
    <div
      slot="content"
      class="clinician-edit-measure-responses-in-overlay"
    >
      <single-measure
        :custom-measure-id="measure.client_measure_id"
        :current-page="measure.firstPageWithMissingAnswers"
        :clinician-editing="true"
        @customizationFinished="measureFinished"
      />
    </div>
  </Overlay>
</template>
<script>
import Overlay from '@/components/clinician/Overlay'
import SingleMeasure from '@/components/measures/SingleMeasure'
import { Helpers } from '@/mixins/Helpers'
import { componentRefreshSingleton } from '@/mixins/ComponentRefresh/ComponentRefreshSingleton'

export default {
  name: 'ClinicianEditMeasureResponses',
  components: { Overlay, SingleMeasure },
  mixins: [Helpers],
  props: ['measure', 'patient', 'afterClose'],
  data () {
    return {
      skippedAmount: this.measure.skipped_amount
    }
  },
  computed: {
    measureTitle () {
      return this.measure.clinicalAdministered ? '' : `<span class="measure-abb">${this.$t('editResponses')} - ${this.measure.abbreviation}</span> <br>`
    },
    overlayTitle () {
      return `${this.measureTitle} ${this.getRespondentTitleData()}`
    }
  },
  methods: {
    close () {
      this.$emit('finish')
      componentRefreshSingleton.refreshComponents()
    },
    measureFinished () {
      this.$emit('finish')
      componentRefreshSingleton.refreshComponents()
    },
    getRespondentTitleData () {
      return `
        <div class="edit-responses-sub-title">
          <span class="name">${this.patient.name || ''} (${this.patient.patient_relation || ''})</span> <br>
          <span class="mrn">${this.getMrnAndPatientData()}</span>
        </div>
      `
    },
    getMrnAndPatientData () {
      let text = this.patient.patient_relation_id === 1 ? '' : `${this.$t('patient')}: ${this.patient.patient_name || ''} `
      text += `${this.$t('mrn')}: ${this.patient.patient_mrn || ''}`
      return text
    },
    promptBeforeClose () {
      return new Promise((resolve) => {
        const promptOptions = {
          title: this.$t('areYouSureToExit'),
          message: this.measure.is_pre_populate ? this.$t('prePopulateUpdatesSaved') : this.$t('updatesSaved'),
          cancelButton: this.$t('noResume'),
          okButton: this.$t('yesExit')
        }
        this.$promptBeforeAction(promptOptions, () => {
          if (typeof this.afterClose === 'function') {
            this.afterClose()
          }
          resolve()
        })
      })
    }
  }
}
</script>
