<template>
  <div>
    <span v-if="!noText">{{ text }}</span>
    <i class="fas fa-circle-notch fa-spin" />
  </div>
</template>

<script>
export default {
  name: 'LoadingSpinner',
  props: {
    noText: {
      type: Boolean,
      default: false
    },
    customText: {
      type: String,
      default: ''
    }
  },
  computed: {
    text () {
      return this.customText || this.$t('loading')
    }
  }
}
</script>
